<template>
  <!-- LeetCode 千题视频讲解 -->
  <div class="">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">LeetCode 千题视频讲解（免费领取）</div>
            <div class="sch-desc">
              扫码添加助教，免费领取<br />
              一题多解，最优解，让初学者每天10题起步，从题意到思路，过程解法，时间空间复杂度，全解析
            </div>
            <ul class="leet-date">
              <li>
                <div class="val">100 <span>+</span></div>
                <div class="txt">课时时间</div>
              </li>
              <li>
                <div class="val">300 <span>道</span></div>
                <div class="txt">LC题目讲解</div>
              </li>
              <li>
                <div class="val">1300 <span>+</span></div>
                <div class="txt">解法多解</div>
              </li>
              <li>
                <!--                <div class="val">99.9 <span>%</span></div>-->
<!--                <div class="val">5000 <span>+</span></div>-->
<!--                <div class="txt">学员好评</div>-->
              </li>
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 & 免费试听</div>
            </div>
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>

        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
              <img src="@/assets/imgs/priceBanner.png" alt="" />
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div class="prices" >
                    <span style="font-size: 28px; color: #de1306">
                      ${{ comboData[0].usdprice }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{ comboData[0].price }}</span
                    >
                  </div>
                  <!-- <div class="pricesTips">
                    <div class="dollar">支持美元分期付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div> -->
                </div>
                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>
        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
                :current-index="currentIndex"
              />
              <div v-if="stickyToggle" class="price" style="right: -15px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="selectCombo"
                >
                  ${{ comboData[0].usdprice }}</span
                >
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{ comboData[0].price }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <!--  -->
		  <LCfree></LCfree>
          <div class="g-section" style="padding-top: 72px">
            <div class="g-header">
              <h3>LeetCode题目一道道视频讲解</h3>
              <p>初学者即可一天10题，有经验者一天30题</p>
            </div>
            <div class="container">
              <el-row :gutter="28" class="lt-rows">
                <el-col :span="6">
                  <div class="lt-card">
                    <div class="lt-card-hd">解题没章法，无从下手</div>
                    <div class="lt-card-bd">
                      <div class="lt-tit">解题思路</div>
                      <div class="lt-desc">
                        看到题目怎么想<br />
                        如何思考方法技巧<br />
                        哪些算法解决问题
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="lt-card">
                    <div class="lt-card-hd">解法繁多，哪个最好</div>
                    <div class="lt-card-bd">
                      <div class="lt-tit">最优解</div>
                      <div class="lt-desc">
                        所有解法精挑细选<br />
                        每一题最优解是什么<br />
                        节省寻找最优解时间
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="lt-card">
                    <div class="lt-card-hd">分辨不出有意义可通用解法</div>
                    <div class="lt-card-bd">
                      <div class="lt-tit">一题多解</div>
                      <div class="lt-desc">
                        有意义的即使不是最优解<br />
                        可通用可放在其他题的技巧<br />
                        筛选后全都会一一进行讲解
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="lt-card">
                    <div class="lt-card-hd">面试复杂度怎么分析</div>
                    <div class="lt-card-bd">
                      <div class="lt-tit">时间空间复杂度</div>
                      <div class="lt-desc">
                        每道题必会分析其复杂度<br />
                        时间，空间复杂度分析<br />
                        如何快速得到准确结果
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!--  -->
          <div class="g-section">
            <div class="g-header">
              <h3>严格遵从面试流程，面试怎么考怎么讲</h3>
              <p>每道题高质量讲解，算法面试的严谨回答</p>
            </div>
            <div class="container">
              <div class="lc-row">
                <div class="lc-box lc-box-1">
                  <div class="inner">
                    <div class="lc-tt">说明题意</div>
                    <div class="lc-desc">
                      有的题意难懂<br />
                      隐藏Case容易踩坑<br />
                      事先说明<br />
                      防止思考不全面
                    </div>
                  </div>
                </div>
                <div class="lc-box lc-box-2">
                  <div class="inner">
                    <div class="lc-tt">题目重要性</div>
                    <div class="lc-desc">
                      题目在面试的重要程度<br />
                      有意义的好题<br />
                      进行说明<br />
                      不重要的也会提醒
                    </div>
                  </div>
                </div>
                <div class="lc-box lc-box-3">
                  <div class="inner">
                    <div class="lc-tt">解题思路</div>
                    <div class="lc-desc">
                      看见这个题怎么想<br />
                      往哪方面想<br />
                      怎么一步步去推导算法<br />
                      用什么方法
                    </div>
                  </div>
                </div>
                <div class="lc-box lc-box-4">
                  <div class="inner">
                    <div class="lc-tt">现场手写代码</div>
                    <div class="lc-desc">
                      一行行手写代码<br />
                      每一行什么意思<br />
                      为什么这么写<br />
                      每个变量的设置
                    </div>
                  </div>
                </div>
              </div>
              <div class="lc-row lc-row2" style="margin-top: -90px">
                <div class="lc-box lc-box-5">
                  <div class="inner">
                    <div class="lc-tt">最优解&一题多解</div>
                    <div class="lc-desc">
                      最优解必说<br />
                      有意义的解法进行一题多解<br />
                      省去找答案<br />
                      分辨解法好坏
                    </div>
                  </div>
                </div>
                <div class="lc-box lc-box-6">
                  <div class="inner">
                    <div class="lc-tt">手跑Test Case</div>
                    <div class="lc-desc">
                      写完代入Case<br />
                      代码一步步如何变化<br />
                      面试必会让<br />
                      求职者现场跑
                    </div>
                  </div>
                </div>
                <div class="lc-box lc-box-7">
                  <div class="inner">
                    <div class="lc-tt">时间&空间复杂度</div>
                    <div class="lc-desc">
                      时间复杂度<br />
                      空间复杂度<br />
                      每道题必讲<br />
                      如何分析得出
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <code-style></code-style>
          <java-explain></java-explain>

          <!--  -->
          <div class="g-section cam-section">
            <div class="g-header">
              <h3>高效刷题，比自己刷题，效率提升50%以上</h3>
              <p>不仅仅是事半功倍，而且做得快，理解的深</p>
            </div>
            <div class="container">
              <div class="cam-row">
                <div class="cam-card">
                  <div class="cam-tit">自己独自刷题</div>
                  <ul class="cam-list">
                    <li>有答案看不懂，几个小时弄不明白</li>
                    <li>Discuss答案众说纷纭，无法确认最优解</li>
                    <li>做题没方法，解题没思路</li>
                    <li>麻木刷题，根本不知面试考什么</li>
                  </ul>
                </div>
                <div class="cam-v">
                  <div>提升50%</div>
                </div>
                <div class="cam-card dui">
                  <div class="cam-tit">跟着视频刷题</div>
                  <ul class="cam-list">
                    <li>文字答案难懂，视频一讲就透</li>
                    <li>最优解一题多解都有，不需要自己筛选</li>
                    <li>除了方法和思路，还有技巧和总结</li>
                    <li>考点分析，不重要进行说明</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="g-section sx-section">
            <div class="g-header">
              <h3>LeetCode 300题最终分类顺序表，先易后难科学划分排序</h3>
              <p>以题型划分章，先易后难顺序排列题目作为节</p>
            </div>
            <div class="container">
              <div class="sx-cell sx-photo-rt">
                <div class="sx-photo">
                  <img src="../../assets/images/timu/pp1.png" alt="" />
                </div>
                <div class="sx-grid">
                  <div class="sx-icon">
                    <img src="../../assets/images/timu/so-rt.png" alt="" />
                  </div>
                  <div class="sx-card">
                    <div class="sx-hd">
                      <span class="lg">以类别划分题目：</span
                      ><br />不以题号划分题目章节
                    </div>
                    <div class="sx-bd">
                      <div class="sx-row">
                        <div class="sx-tt">
                          <span>章的划分</span
                          ><img
                            src="../../assets/images/timu/dd.png"
                            alt=""
                          /><span>题型来划分</span>
                        </div>
                        <div class="sx-desc">
                          每章就是一类题型，一共划分19章大题型
                        </div>
                      </div>
                      <div class="sx-row">
                        <div class="sx-tt">
                          <span>章的顺序</span
                          ><img
                            src="../../assets/images/timu/dd.png"
                            alt=""
                          /><span>先易后难划分</span>
                        </div>
                        <div class="sx-desc">
                          简单的题型放前面，难的题型放后面
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sx-cell sx-photo-lt">
                <div class="sx-grid">
                  <div class="sx-icon">
                    <img src="../../assets/images/timu/so-lt.png" alt="" />
                  </div>
                  <div class="sx-card">
                    <div class="sx-hd">
                      <span class="lg">先易后难顺序排列题目：</span
                      ><br />简单的题目在前，难的题目在后
                    </div>
                    <div class="sx-bd">
                      <div class="sx-row">
                        <div class="sx-tt">题目后面标注对应方法类型</div>
                        <div class="sx-desc">
                          每道题的标题后面，都标注有此题解法的方法类型
                        </div>
                      </div>
                      <div class="sx-row">
                        <div class="sx-tt">不重要等题目进行说明</div>
                        <div class="sx-desc">
                          极难，不重要等题目进行标注说明，减少做题量
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sx-photo">
                  <img src="../../assets/images/timu/pp2.png" alt="" />
                </div>
              </div>
            </div>
            <!-- <div class="description center-6">
              *600题分类顺序表仅限购买「LeetCode 1-600题」及以上版本班课套餐
            </div> -->
          </div>
          <!--  -->
          <div class="g-section vs-section">
            <div class="g-header">
              <h3>对比网上免费的讲解，我们的讲解更细致全面针对面试</h3>
              <p>为学生考虑，为面试量身打造，而不仅仅是刷题学习知识</p>
            </div>
            <div class="container">
              <table class="vs-table">
                <tr>
                  <td width="130"></td>
                  <td>
                    <div class="tt">网上免费讲解</div>
                  </td>
                  <td width="130">
                    <div class="vs">VS</div>
                  </td>
                  <td class="v-color">
                    <div class="tt">CSON的LeetCode视频讲解</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">时间长</div>
                  </td>
                  <td>
                    一题讲解10～30分钟不等<br />
                    语速缓慢，表达重复<br />
                    每个题多5分钟，1000题就是多出5000分钟
                  </td>
                  <td></td>
                  <td class="v-color">
                    语言精练，直中题目要害<br />
                    录制过程有意加快语速讲解<br />
                    减少看视频时间，大多数题目3-10分钟不等
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">最优解</div>
                  </td>
                  <td>
                    讲解并不是最优解，最优解还需要自己寻找和理解<br />
                    讲解的只是普通解法，面试却是考察最优解法
                  </td>
                  <td></td>
                  <td class="v-color">
                    最优解几乎必讲，“几乎”是因为有些最优解<br />
                    是偏解非常人能想出（1000题中数量&lt;=20）<br />
                    面试不可能在有限时间做出来<br />
                    但出现偏解每个题也会在讲解中说明
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">一题多解</div>
                  </td>
                  <td>
                    大多数讲解都是单一解法，方法解法不同<br />
                    因为时间精力有限，即使需要掌握但也只讲解一种
                  </td>
                  <td></td>
                  <td class="v-color">
                    有意义的解法，即使不是最优解<br />
                    可能在其他题目中通用，也会讲解<br />
                    不放过任何一个有意义的解法
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">清晰程度</div>
                  </td>
                  <td>
                    讲解细节有欠缺，没达到清楚明白<br />
                    听一遍还是听不懂
                  </td>
                  <td></td>
                  <td class="v-color">
                    讲解清楚明白<br />
                    每一题立志让每一个学生听懂
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">复杂度</div>
                  </td>
                  <td>
                    忽略时间复杂度，空间复杂度的分析<br />
                    但这个环节是面试必问的，一旦答错，满盘皆输
                  </td>
                  <td></td>
                  <td class="v-color">
                    每一题必分析<br />
                    给出标准和参考复杂度
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">Test Case</div>
                  </td>
                  <td>
                    忽略跑Case的重要性<br />
                    不跑Case很多题是讲不清楚的<br />
                    而且面试也会现场让面试者跑一次Case
                  </td>
                  <td></td>
                  <td class="v-color">
                    在讲解完毕会以Case为例子讲一遍每一行的变化<br />
                    或者以Case代入进行讲解
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">题目不全</div>
                  </td>
                  <td>
                    大多数讲解只是少部分<br />
                    因为题目太多坚持不了，不能每一题都讲
                  </td>
                  <td></td>
                  <td class="v-color">
                    LeetCode<br />
                    1-1000的所有算法题，全部讲解
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">无关话题</div>
                  </td>
                  <td>
                    讲解无关话题说的较多<br />
                    或以直播形式讲解后期裁剪<br />
                    很多和同学的互动交流对于看视频没有意义
                  </td>
                  <td></td>
                  <td class="v-color">
                    每题精讲，每个题目都是单独成视频录制<br />
                    一题一个视频，无关话题绝不会说，压缩时间
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">老师风格</div>
                  </td>
                  <td>
                    各种讲解老师风格不一样<br />
                    切换不同老师需要适应时间
                  </td>
                  <td></td>
                  <td class="v-color">
                    讲师始终一人，风格统一<br />
                    无需学生切换适应
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="tt">专业程度</div>
                  </td>
                  <td>
                    网上讲解很多是学生录制<br />
                    或者在职上班，并没有时间精力刷太多题<br />
                    对题目专业程度不够
                  </td>
                  <td></td>
                  <td class="v-color">
                    讲师刷题经验丰富<br />
                    题量3000+，录制讲解1000+<br />
                    其他课程众多，经验丰富
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 自己学慢的理由，80%的时间都可以节省 -->
          <div class="g-section">
            <div class="container">
              <div class="ly-cells">
                <div class="g-header">
                  <h3>自己学慢的理由，可以节省大量的时间</h3>
                  <p>
                    完全可以节省这些机械性工作，让你直达目的，最短速成理解算法
                  </p>
                </div>
                <el-row :gutter="40" style="margin-bottom: 50px">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/timu/ly1.png" /><span
                          >难题意</span
                        >
                      </div>
                      <div class="bd">
                        很多题意表述不清造成歧义<br />
                        或者有些情况没有说明<br />
                        需要自己猜
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/timu/ly2.png" /><span
                          >找答案</span
                        >
                      </div>
                      <div class="bd">
                        网上答案众多<br />
                        不知道是否这个解法是最优解<br />
                        这个题是否需要一题多解<br />
                        哪些解法有意义，哪些解法不用看
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/timu/ly3.png" /><span
                          >辨优劣</span
                        >
                      </div>
                      <div class="bd">
                        有些解法代码规范一塌糊涂<br />
                        代码累赘重复<br />
                        好的解法一行解决，坏的解法5行<br />
                        初学者很难分辨
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="40" type="flex" justify="center">
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/timu/ly4.png" /><span
                          >看不懂</span
                        >
                      </div>
                      <div class="bd">
                        很多解法只有代码没有注释<br />
                        理解起来费劲<br />
                        经常研究2-3小时也理解不了<br />
                        浪费了大量的时间
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="jserv-cell">
                      <div class="hd">
                        <img src="../../assets/images/timu/ly5.png" /><span
                          >没人问</span
                        >
                      </div>
                      <div class="bd">
                        很多小伙伴都是孤军奋战<br />
                        有问题没人讨论，只能自己闭门造车<br />
                        有时候高水平的一句话<br />
                        可以让人豁然开朗
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 千题录播讲解随时观看，代码升级讲师互动 -->
          <thousands-questions></thousands-questions>
          <!-- 我们的海内外的学生，都拿到了这些公司的Offer -->
          <offer></offer>
          <free-system></free-system>
          <!-- 价格方案 -->
          <div class="g-section pp-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div class="gg-card" style="margin: 0 auto !important">
                    <el-row>
                      <el-col :span="24">
                        <div class="gg-yh-cell">
                          <div class="gg-yh-grid">
                            <div class="gg-price">最低仅售</div>
                            <div class="gg-y-price">
                              ${{ comboData[0].usdprice }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                              一题多解，最优解，轻松一天10题
                              <br />
                              从题意到思路，过程解法，时间空间复杂度，全解析
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i>扫码添加助教微信
                                免费领取
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>LeetCode
                                300题分类顺序表
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i>初学者一天10题
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >三种语言代码（Java/C++/Python)
                              </li>
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i>微信群答疑-->
<!--                              </li>-->
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                  type="buy"
                                  @click="buyCombo"
                                  :class="[comboData.length > 1 ? 'red' : '']"
                              >立即购买
                              </el-button>
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <!-- <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div class="gg-card">
                    <div
                      class="gg-yh-cell gg-jc-cell"
                      @click="Pcselect('1')"
                      :class="pp[0]"
                      v-if="comboData.length > 0"
                    >
                      <div class="gg-yh-grid blue">
                        <div class="gg-y-price">
                          ${{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice
                          }}
                        </div>
                        <div class="gg-price">
                          ¥{{
                            comboData[0].discountPrice > 0
                              ? comboData[0].discountPrice
                              : comboData[0].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[0].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[0].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[0].discountPrice == 0"
                        >
                          基础版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          一题多解，最优解，轻松一天10题<br />
                          从题意到思路，过程解法，时间空间复杂度，全解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>录播课程，随时观看
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>Java, Python,
                            C++3种代码答案
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>最优解 &amp;
                            一题多解
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >购买3天内，添加客服微信，转发海报后
                            <br />
                            免费获得15天答疑服务
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-gj-cell"
                      @click="Pcselect('2')"
                      :class="pp[1]"
                      v-if="comboData.length > 1"
                    >
                      <div class="gg-yh-grid blue">
                        <div class="gg-y-price">
                          ${{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice
                          }}
                        </div>
                        <div class="gg-price">
                          ¥{{
                            comboData[1].discountPrice > 0
                              ? comboData[1].discountPrice
                              : comboData[1].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[1].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[1].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[1].discountPrice == 0"
                        >
                          基础版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          一题多解，最优解，轻松一天10题<br />
                          从题意到思路，过程解法，时间空间复杂度，全解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>录播课程，随时观看
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>Java, Python,
                            C++3种代码答案
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>最优解 &amp;
                            一题多解
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >购买3天内，添加客服微信，转发海报后
                            <br />
                            免费获得15天答疑服务
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-qj-cell"
                      @click="Pcselect('3')"
                      :class="pp[2]"
                      v-if="comboData.length > 2"
                    >
                      <div class="gg-yh-grid purple">
                        <div class="gg-y-price">
                          ${{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice
                          }}
                        </div>
                        <div class="gg-price">
                          ¥{{
                            comboData[2].discountPrice > 0
                              ? comboData[2].discountPrice
                              : comboData[2].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[2].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[2].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[2].discountPrice == 0"
                        >
                          高级版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          一题多解，最优解，轻松一天10题<br />
                          从题意到思路，过程解法，时间空间复杂度，全解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>录播课程，随时观看
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>Java, Python,
                            C++3种代码答案
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>最优解 &amp;
                            一题多解
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >购买3天内，添加客服微信，转发海报后
                            <br />
                            免费获得15天答疑服务
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="gg-yh-cell gg-qj-cell"
                      @click="Pcselect('4')"
                      :class="pp[3]"
                      v-if="comboData.length > 3"
                    >
                      <div
                        class="gg-yh-grid"
                        style="
                          background-image: linear-gradient(
                            to right,
                            #f24e4a,
                            #f69777
                          ) !important;
                        "
                      >
                        <div class="gg-y-price">
                          ${{
                            comboData[3].usddiscountPrice > 0
                              ? comboData[3].usddiscountPrice
                              : comboData[3].usdprice
                          }}
                        </div>
                        <div class="gg-price">
                          ¥{{
                            comboData[3].discountPrice > 0
                              ? comboData[3].discountPrice
                              : comboData[3].price
                          }}
                        </div>
                        <div
                          class="gg-base-price"
                          v-if="comboData[3].usddiscountPrice != 0"
                        >
                          原价：${{ comboData[3].usdprice }}
                        </div>
                        <div
                          class="gg-tag"
                          v-if="comboData[3].discountPrice == 0"
                        >
                          旗舰版
                        </div>
                        <div class="gg-tag-discountTip" v-else>
                          <img src="@/assets/imgs/discountTip.png" alt="" />
                        </div>
                      </div>
                      <div class="gg-yh-body">
                        <div class="tip">
                          一题多解，最优解，轻松一天10题<br />
                          从题意到思路，过程解法，时间空间复杂度，全解析
                        </div>
                        <ul class="gg-yh-item">
                          <li>
                            <i class="iconfont icon-dui"></i>录播课程，随时观看
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >自定义开课日期，自由安排
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>Java, Python,
                            C++3种代码答案
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i>最优解 &amp;
                            一题多解
                          </li>
                          <li>
                            <i class="iconfont icon-dui"></i
                            >购买3天内，添加客服微信，转发海报后
                            <br />
                            免费获得15天答疑服务
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="gaoduan-yh-popover">
                    <div class="sk-cells">
                      <div class="sk-heading">
                        <div style="width: 20%">LC题目数量</div>
                        <div style="width: 15%">有效期</div>
                        <div style="width: 15%">600分类顺序</div>
                        <div style="width: 15%">价格USD</div>
                        <div style="width: 15%">选择</div>
                      </div>
                      <div
                        class="sk-row"
                        style="width: 700px"
                        @click="Pcselect('1')"
                        :class="{ active: Pselect === '1' }"
                        v-if="comboData.length > 0"
                      >
                        <div style="width: 20%">1-300题</div>
                        <div>3个月</div>
                        <div style="width: 15%">
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[0].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger" style="width: 15%">
                          {{
                            comboData[0].usddiscountPrice > 0
                              ? comboData[0].usddiscountPrice
                              : comboData[0].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="1"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        style="width: 700px"
                        @click="Pcselect('2')"
                        :class="{ active: Pselect === '2' }"
                        v-if="comboData.length > 1"
                      >
                        <div style="width: 20%">301-600题</div>
                        <div>3个月</div>
                        <div style="width: 15%">
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[1].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger" style="width: 15%">
                          {{
                            comboData[1].usddiscountPrice > 0
                              ? comboData[1].usddiscountPrice
                              : comboData[1].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="2"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        style="width: 700px"
                        @click="Pcselect('3')"
                        :class="{ active: Pselect === '3' }"
                        v-if="comboData.length > 2"
                      >
                        <div style="width: 20%">1-600题</div>
                        <div>6个月</div>
                        <div style="width: 15%">
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[2].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger" style="width: 15%">
                          {{
                            comboData[2].usddiscountPrice > 0
                              ? comboData[2].usddiscountPrice
                              : comboData[2].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="3"></el-radio>
                        </div>
                      </div>
                      <div
                        class="sk-row"
                        style="width: 700px"
                        @click="Pcselect('4')"
                        :class="{ active: Pselect === '4' }"
                        v-if="comboData.length > 3"
                      >
                        <div style="width: 20%">1-1000题</div>
                        <div>8个月</div>
                        <div style="width: 15%">
                          <img
                            src="../../assets/images/scb/dui.png"
                            alt=""
                            v-if="comboData[3].hasTest"
                          />
                          <img
                            src="../../assets/images/scb/cuo.png"
                            alt=""
                            v-else
                          />
                        </div>
                        <div class="text-danger" style="width: 15%">
                          {{
                            comboData[3].usddiscountPrice > 0
                              ? comboData[3].usddiscountPrice
                              : comboData[3].usdprice || 0
                          }}
                        </div>
                        <div>
                          <el-radio v-model="Pselect" label="4"></el-radio>
                        </div>
                      </div>
                    </div>
                    <div class="sk-tips">
                      <span class="text-warin"
                        >*点击选中套餐，可立即下单订购</span
                      >
                      <span class="tag">支持花呗分期</span>
                      <span class="tag">支持美元分期付款</span>
                    </div>
                    <div class="skill-yh-foot">
                      <el-button plain type="warning" @click="buyCombo"
                        >立即购买
                      </el-button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    :span="12"
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <courseMeal v-if="TabActiveIndex == 'course_meal'" id="2"></courseMeal>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="2"></presales-consultation>
        </div>
        <!--        <courseMeal v-if="TabActiveIndex == 'course_content'" :course-i-d="courseId"></courseMeal>-->
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>
    <!-- 提问 -->
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "../../utils/courseMix";
import courseDetail from "../../components/course/courseDetail";
import courseDatas from "../../utils/courseDatas";
import Menus from "../../components/course/menu";
import codeStyle from "./components/codeStyle";
import javaExplain from "./components/javaExplain";
import thousandsQuestions from "./components/thousandsQuestions";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import polyVideo from "./components/polyVideo";
import presalesConsultation from "./components/presalesConsultation";
import courseMeal from "./components/courseMeal";
import pageSelect from "./components/pageSelect";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help.vue";
import { getMinimumPrice } from "@/service/subscribe";
import LCfree from "./components/LCfree.vue";
export default {
  components: {
    leetDialog,
    courseDetail,
    Menus,
    codeStyle,
    javaExplain,
    thousandsQuestions,
    offer,
    freeSystem,
    polyVideo,
    presalesConsultation,
    courseMeal,
    pageSelect,
    pageStickySelect,
    helpClass,
	  LCfree
  },
  mixins: [courseMix, courseDatas],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "LeetCode千题视频讲解",
      videoShow: false,
      dr: 13,
      br: 13,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 6,
      Pselect: "1",
      img: require("../../assets/images/timu/banner.jpg"),
      form: {
        region: "v1",
      },
      // 价格方案
      skV1: "LeetCode 1-300",
      skV2: 1,
      skV3: "1个月",
      kprice: "750",
      mkprice: "115.65",
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课目录", index: "2", tag: "course_content" },
        // { name: "用户评价", index: "3", tag: "course_comment" },
        // { name: "售前咨询", index: "4", tag: "course_question" },
      ],
      searchQuestionKey: "",
      currentIndex: "0",
      finalCourse: {},
      recommendClasses: [],
      pp: ["ac1", "ac2", "ac3", "ac4"],
      helpVisible: false,
      miniumPrice: {},
	  
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    getMinimumPrice().then((res) => {
      if (res.success) {
        this.miniumPrice = res.result;
      }
    });
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      switch (command) {
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
      }
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    SkChange: function (e) {
      console.log(e);
      this.kprice = e;
      this.mkprice = (e / 6.96).toFixed(2);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
    goSubscribe() {
      this.$router.push("/subscribe");
    },
  },
  watch: {
    comboData(val) {
      if (val.length > 0) {
        this.selectCombo = this.comboData[0];
      }
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/2-LeetCodeVideoCourse.css");
</style>
